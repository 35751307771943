import React from 'react'
import './faq.css'

export default function faq() {
  return (
    <>

      <div className='container' style={{ paddingTop: 130 }}>
        <h1 className='faq_title'>FAQ.</h1>
        <div className='row'>
          <p className='faq_head'>Does the writer follow the guidelines mentioned in the order?</p>
          <p className='faq_sub_text'>When ordering assignments at Quick Assignments service,the student needs to provide all the details to the expert, along with all the material for the assignments. The expert will then carefully go through all the guidelines and complete the paper efficiently. The student will also be able to discuss the requirements with the expert through the live chat option to ensure that the expert is aware of all the requirements related to the task and is progressing in the right direction.</p>

          <p className='faq_head'>How to ensure that the content of the assignment is 100% unique or plagiarismfree?</p>
          <p className='faq_sub_text'>When a student pays an expert at Quick Assignment then they are assured that their assignments will be 100% original. Our experts prepare well-researched content which goes through several quality checks done by our quality checkers and also through Turnitin which instantly detects plagiarism. The assignments are prepared from the scratch, they are revised and checked multiple times before sending them to the student. With the solution, we also offer the Turnitin report which is the originality report that proves that the paper is 100% plagiarism free. </p>

          <p className='faq_head'>When do I pay for the paper?</p>
          <p className='faq_sub_text'>When you pay Quick Assignment for taking assignment help then be rest assured that there will be no risk involved when it comes to the payment. The student pays for the essay after the task is completed, revised, and finally submitted. To ensure safety, we ensure that the customers make some deposit when they place the order and that deposit is stored in the company’s account until the order is complete.</p>

          <p className='faq_head'>Can the experts at Quick Assignment provide assignment solutions as the earliest?</p>
          <p className='faq_sub_text'>Our experts and professionals are skilled enough to develop a well-researched paper instantly that too in a few hours. The shortest delivery for assignments is 8-10 hours, but it all depends on the type of assignment, a number of words, and the complications that help to decide the number of hours taken to complete the assignment. </p>

          <p className='faq_head'>Can anyone else find out that I have taken help from Quick assignment?</p>
          <p className='faq_sub_text'>No, you don’t need to worry about your privacy because our IT experts do their duty efficiently and ensure that your details are safe and secure. The experts make use of advanced technology like cybersecurity to enhance the online experience and also ensure 100% safety, which includes the messages sent to the expert and other information that will be encrypted. This means that your details will never be at risk. </p>
        </div>
      </div>

    </>
  )
}

