import React, { Component } from 'react'
import LoadingOverlay from "react-loading-overlay";
import * as Constant from "../Constant";
import './Contact.css';
import { toast } from 'react-toastify';
import contact_banner from '../../assets/img/newHome/contact_banner.png'

export class Contact extends Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      details: "",
      fullNameError: null,
      emailError: null,
      detailsError: null,
      isLoading: false,
      Loading: true,

      answer: '',
      isCorret: ''

    };
  }

  validate = () => {
    let fullNameError = "";
    let emailError = "";
    let detailsError = "";

    if (!this.state.fullName) {
      fullNameError = "Please enter full name.";
    }
    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Email cannot be empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid email address.";
      }
    }

    if (!this.state.details) {
      detailsError = "Message cannot be empty.";
    }

    if (fullNameError || emailError || detailsError) {
      this.setState({
        fullNameError,
        emailError,
        detailsError
      });
      return false;
    }
    return true;
  };
  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        fullNameError: null,
        emailError: null,
        textareaError: null,
      });
      this.onContact();
    }
  };
  onContact = async () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("fullname", this.state.fullName);
    formData.append("email", this.state.email);
    formData.append("details", this.state.details);

    await fetch(Constant.ContactUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success === true) {
          this.setState({
            fullName: "",
            email: "",
            details: "",
          });
          // window.location.href = Constant.BaseUrl + "thankyou";
          this.setState({ isLoading: false });

          toast.success('Your request submitted successfully', {
            position: 'top-center',
            width: 100,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
        } else {
          console.error("Error:!!!!", error);
        }
        this.setState({ isLoading: false });
      });
  };

  handleChangeFirstName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({
      fullName: result,
    })
  };

  render() {
    return (
      <>
        <LoadingOverlay styles={{ width: "500" }} width={400}
          active={this.state.isLoading}
          spinner
          fadeSpeed={10}
          text="Message Sending..."
        >

          <h1 className="grades " style={{ fontSize: 55, marginTop: 100 }}>Get in touch <span style={{ color: '#F7631B' }}>with experts </span></h1>
          <p className='contact_sub_text'>Quick Assignment is one of the most reliable and trustworthy assignment services<br></br> providing organizations in the academic writing industry with more than 10 years of experience. </p>


          <div className='row'>
            <div className='col-md-4 col-lg-4 col-sm-12 col-xs-12 col-xl-4' style={{ zIndex: 2 }}>
              <img src={contact_banner} alt='contact_banner' className='contact_img' />
            </div>
            <div className='col-md-7 col-lg-7 col-sm-12 col-xs-12 col-xl-7' style={{ zIndex: 1 }}>
              <div className='bg_black'>
                <div className='row'>
                  <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xs-12'></div>
                  <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xs-12'>
                    <p className='contact_us'>Contact us</p>

                    <div className='form-group'>
                      <input type="text" placeholder='Name' value={this.state.fullName} onChange={this.handleChangeFirstName} className='form-control text_field' />
                      {!!this.state.fullNameError && (
                        <text style={{ marginLeft: "10px", color: 'red' }}>
                          {this.state.fullNameError}
                        </text>
                      )}
                    </div>
                    <div className='form-group'>
                      <input type="text" placeholder='Email' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='form-control text_field' />
                      {!!this.state.emailError && (
                        <text style={{ marginLeft: "10px", color: 'red' }}>
                          {this.state.emailError}
                        </text>
                      )}
                    </div>

                    <div className='form-group'>
                      <textarea placeholder='Write your requirements' value={this.state.details} onChange={(e) => this.setState({ details: e.target.value })} className='form-control text_area'></textarea>
                      {!!this.state.detailsError && (
                        <text style={{ marginLeft: "10px", color: 'red' }}>
                          {this.state.detailsError}
                        </text>
                      )}
                    </div>
                    <div class="d-flex row">
                      <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6 d-flex '>
                      </div>
                      <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <a className="get_started" onClick={this.onSubmit}>Send Message
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1 col-lg-1 col-sm-12 col-xs-12 col-xl-1'></div>
                </div>
              </div>
            </div>
            <div className='col-md-1 col-lg-1 col-sm-12 col-xs-12 col-xl-1'></div>
          </div>
        </LoadingOverlay>
      </>
    )
  }
}
export default Contact







